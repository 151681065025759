import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171'),
	() => import('./nodes/172'),
	() => import('./nodes/173'),
	() => import('./nodes/174'),
	() => import('./nodes/175'),
	() => import('./nodes/176'),
	() => import('./nodes/177'),
	() => import('./nodes/178'),
	() => import('./nodes/179'),
	() => import('./nodes/180'),
	() => import('./nodes/181'),
	() => import('./nodes/182'),
	() => import('./nodes/183'),
	() => import('./nodes/184'),
	() => import('./nodes/185'),
	() => import('./nodes/186'),
	() => import('./nodes/187'),
	() => import('./nodes/188'),
	() => import('./nodes/189'),
	() => import('./nodes/190'),
	() => import('./nodes/191'),
	() => import('./nodes/192'),
	() => import('./nodes/193'),
	() => import('./nodes/194'),
	() => import('./nodes/195'),
	() => import('./nodes/196'),
	() => import('./nodes/197'),
	() => import('./nodes/198'),
	() => import('./nodes/199'),
	() => import('./nodes/200'),
	() => import('./nodes/201'),
	() => import('./nodes/202'),
	() => import('./nodes/203'),
	() => import('./nodes/204'),
	() => import('./nodes/205'),
	() => import('./nodes/206'),
	() => import('./nodes/207'),
	() => import('./nodes/208'),
	() => import('./nodes/209'),
	() => import('./nodes/210'),
	() => import('./nodes/211'),
	() => import('./nodes/212'),
	() => import('./nodes/213'),
	() => import('./nodes/214'),
	() => import('./nodes/215'),
	() => import('./nodes/216'),
	() => import('./nodes/217'),
	() => import('./nodes/218'),
	() => import('./nodes/219'),
	() => import('./nodes/220'),
	() => import('./nodes/221'),
	() => import('./nodes/222'),
	() => import('./nodes/223'),
	() => import('./nodes/224'),
	() => import('./nodes/225')
];

export const server_loads = [0,24,27,26,28,29,30,32,35,2,4,5,6,7,8,9,10,11,12,13,14,15,16,22,17,19,23];

export const dictionary = {
		"/": [~39],
		"/admin": [~121,[24],[25]],
		"/admin/companies": [~122,[24],[25]],
		"/admin/companies/[companySlug]": [~123,[24],[25]],
		"/admin/companies/[companySlug]/edit": [~124,[24],[25]],
		"/admin/companies/[companySlug]/edit/accesses": [125,[24],[25]],
		"/admin/companies/[companySlug]/edit/licenses": [126,[24],[25]],
		"/admin/companies/[companySlug]/projects": [~127,[24],[25]],
		"/admin/companies/[companySlug]/users": [~128,[24],[25]],
		"/admin/devtools": [129,[24],[25]],
		"/admin/emails": [~130,[24],[25]],
		"/admin/emails/email-dashboard": [~131,[24],[25]],
		"/admin/event-log": [~132,[24],[25]],
		"/admin/licenses": [133,[24],[25]],
		"/admin/licenses/susan": [~134,[24],[25]],
		"/admin/new-company": [135,[24],[25]],
		"/admin/questions": [~136,[24],[25]],
		"/admin/questions/add": [~146,[24],[25]],
		"/admin/questions/options": [147,[24],[25]],
		"/admin/questions/options/add": [~151,[24],[25]],
		"/admin/questions/options/[optionId]": [~148,[24],[25]],
		"/admin/questions/options/[optionId]/edit": [~149,[24],[25]],
		"/admin/questions/options/[optionId]/localizations/[locale]": [~150,[24],[25]],
		"/admin/questions/restructure": [~152,[24],[25]],
		"/admin/questions/sets": [~153,[24],[25]],
		"/admin/questions/sets/[questionSetId]": [~154,[24,27],[25]],
		"/admin/questions/sets/[questionSetId]/access": [~155,[24,27],[25]],
		"/admin/questions/sets/[questionSetId]/edit-details": [~156,[24,27],[25]],
		"/admin/questions/sets/[questionSetId]/print": [~157],
		"/admin/questions/[questionId]": [137,[24,26],[25]],
		"/admin/questions/[questionId]/details": [138,[24,26],[25]],
		"/admin/questions/[questionId]/edit": [139,[24,26],[25]],
		"/admin/questions/[questionId]/localizations": [140,[24,26],[25]],
		"/admin/questions/[questionId]/localizations/[locale]": [~141,[24,26],[25]],
		"/admin/questions/[questionId]/options": [~142,[24,26],[25]],
		"/admin/questions/[questionId]/scores": [~143,[24,26],[25]],
		"/admin/questions/[questionId]/ungc": [~144,[24,26],[25]],
		"/admin/questions/[questionId]/update-ungc": [~145,[24,26],[25]],
		"/admin/sent-emails/[email]/[emailType]": [~158],
		"/admin/suggested-follow-ups": [~159,[24],[25]],
		"/admin/suggested-follow-ups/[questionId]": [~160,[24,28],[25]],
		"/admin/suggested-follow-ups/[questionId]/localizations": [~161,[24,28],[25]],
		"/admin/taxonomy": [162,[24,29],[25]],
		"/admin/taxonomy/version": [182,[24,29],[25]],
		"/admin/taxonomy/version/[versionId]": [~183,[24,29],[25]],
		"/admin/taxonomy/year": [~184,[24,29],[25]],
		"/admin/taxonomy/[versionId]": [163,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/activities": [164,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/activities/[activityId]": [~165,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/activity-based": [~166,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/categories": [~167,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/categories/edit": [~168,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/company-wide": [~169,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/expiration": [~170,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/kpi-texts": [171,[24,29,30,31],[25]],
		"/admin/taxonomy/[versionId]/kpi-texts/[kpi]": [~172,[24,29,30,31],[25]],
		"/admin/taxonomy/[versionId]/question-sets": [~176,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/question-sets/[setId]": [~177,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/question/[setId]/[questionId]": [~173,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/question/[setId]/[questionId]/localizations/[locale]": [~174,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/question/[setId]/[questionId]/update-scoring": [~175,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/reusable-group": [~178,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/reusable-group/[groupId]": [~179,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/sectors": [180,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/sectors/[sectorId]": [~181,[24,29,30],[25]],
		"/admin/transparency-act-steps": [~185,[24],[25]],
		"/admin/transparency-act-steps/add": [189,[24],[25]],
		"/admin/transparency-act-steps/checklist-item": [~190,[24],[25]],
		"/admin/transparency-act-steps/checklist-item/[checklistItemId]": [~191,[24],[25]],
		"/admin/transparency-act-steps/checklist-item/[checklistItemId]/add-localization": [~192,[24],[25]],
		"/admin/transparency-act-steps/[taStepId]": [~186,[24],[25]],
		"/admin/transparency-act-steps/[taStepId]/add-checklist-item": [187,[24],[25]],
		"/admin/transparency-act-steps/[taStepId]/add-localization": [~188,[24],[25]],
		"/admin/users": [~193,[24],[25]],
		"/admin/users/[userId]": [~194,[24],[25]],
		"/admin/users/[userId]/edit": [~195,[24],[25]],
		"/admin/workers": [~196,[24,32],[25]],
		"/admin/workers/mailgun_status_worker": [~197,[24,32],[25]],
		"/admin/workers/message_email_worker": [~198,[24,32],[25]],
		"/admin/workers/notification_worker": [~199,[24,32],[25]],
		"/admin/workers/project_worker": [~200,[24,32],[25]],
		"/admin/workers/send_email_worker": [~201,[24,32],[25]],
		"/admin/workers/suggested_follow_up_available_worker": [~202,[24,32],[25]],
		"/admin/workers/survey_deadline_reminder_worker": [~203,[24,32],[25]],
		"/data-processing-agreement": [204,[33]],
		"/login": [205],
		"/logout": [206],
		"/maintenance": [207],
		"/notifications": [~208,[34]],
		"/survey-invitation/[invitationId]": [~209,[35]],
		"/survey-invitation/[invitationId]/access-requested": [~210,[35]],
		"/survey-invitation/[invitationId]/company-created": [~211,[35]],
		"/survey-invitation/[invitationId]/declined": [213,[35]],
		"/survey-invitation/[invitationId]/decline": [212,[35]],
		"/survey-invitation/[invitationId]/forwarded": [215,[35]],
		"/survey-invitation/[invitationId]/forward": [214,[35]],
		"/survey-invitation/[invitationId]/new-company": [~216,[35]],
		"/survey-invitation/[invitationId]/new-company/access-requested": [217,[35]],
		"/survey-invitation/[invitationId]/no-access": [~218,[35]],
		"/survey-invitation/[invitationId]/request-access/[companyId]": [~219,[35]],
		"/survey-invitation/[invitationId]/request-access/[companyId]/access-requested": [~220,[35]],
		"/survey-invitation/[invitationId]/select-company": [~221,[35]],
		"/terms-of-use": [222,[36]],
		"/user": [223,[37],[38]],
		"/user/access-tokens": [~224,[37],[38]],
		"/user/access-tokens/create": [~225,[37],[38]],
		"/[companySlug]": [~40,[2],[3]],
		"/[companySlug]/admin/add-company": [~41,[2],[3]],
		"/[companySlug]/company": [~42,[2,4],[3]],
		"/[companySlug]/company/accesses": [~43,[2,4],[3]],
		"/[companySlug]/company/assessments": [~44,[2,4],[3]],
		"/[companySlug]/company/documents": [~45,[2,4],[3]],
		"/[companySlug]/company/edit": [46,[2,4],[3]],
		"/[companySlug]/company/licenses": [~47,[2,4],[3]],
		"/[companySlug]/company/overview": [~48,[2,4],[3]],
		"/[companySlug]/company/saq-results": [~49,[2,4],[3]],
		"/[companySlug]/company/users": [~50,[2,4],[3]],
		"/[companySlug]/customers": [~51,[2],[3]],
		"/[companySlug]/customers/[customerId]": [~52,[2],[3]],
		"/[companySlug]/customers/[customerId]/overview": [53,[2,5],[3]],
		"/[companySlug]/customers/[customerId]/overview/messages": [~54,[2,5],[3]],
		"/[companySlug]/follow-ups": [55,[2,6],[3]],
		"/[companySlug]/follow-ups/received": [~56,[2,6],[3]],
		"/[companySlug]/follow-ups/received/[senderId]/[questionId]": [~57,[2,6],[3]],
		"/[companySlug]/follow-ups/sent": [~58,[2,6],[3]],
		"/[companySlug]/follow-ups/sent/[receiverId]/[questionId]": [~59,[2,6],[3]],
		"/[companySlug]/projects": [~60,[2,7],[3]],
		"/[companySlug]/projects/add": [~67,[2,7],[3]],
		"/[companySlug]/projects/[projectId]": [~61,[2,7],[3]],
		"/[companySlug]/projects/[projectId]/edit-suppliers-list": [~62,[2,7],[3]],
		"/[companySlug]/projects/[projectId]/preview": [~63,[2,7],[3]],
		"/[companySlug]/projects/[projectId]/preview/print": [~64],
		"/[companySlug]/projects/[projectId]/send-survey": [~65,[2,7],[3]],
		"/[companySlug]/projects/[projectId]/settings": [~66,[2,7],[3]],
		"/[companySlug]/saq/[questionSetId]": [~68,[8]],
		"/[companySlug]/saq/[questionSetId]/answer/[...questionPath]": [~69,[8]],
		"/[companySlug]/saq/[questionSetId]/finish": [~70,[9]],
		"/[companySlug]/suppliers": [~71,[2,10],[3]],
		"/[companySlug]/suppliers/add": [~86,[2,10],[3]],
		"/[companySlug]/suppliers/upload": [~87,[2,10],[3]],
		"/[companySlug]/suppliers/upload/[uploadId]": [~88,[2,10],[3]],
		"/[companySlug]/suppliers/upload/[uploadId]/status": [~89,[2,10],[3]],
		"/[companySlug]/suppliers/[supplierId]": [~72,[2,10],[3]],
		"/[companySlug]/suppliers/[supplierId]/edit": [~73,[2,10],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview": [74,[2,10,11],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/documents": [~75,[2,10,11],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/documents/[uploadId]": [~76,[2,10,11],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/follow-ups": [~77,[2,10,11],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/history": [78,[2,10,11],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/messages": [~79,[2,10,11],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/results": [~80,[2,10,11],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/results/[projectId]": [~81,[2,10,11],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/results/[projectId]/print": [~82],
		"/[companySlug]/suppliers/[supplierId]/overview/results/[projectId]/ungc": [83,[2,10,11,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/results/[projectId]/ungc/category": [~84,[2,10,11,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/results/[projectId]/ungc/themes": [~85,[2,10,11,12],[3]],
		"/[companySlug]/surveys": [~90,[2],[3]],
		"/[companySlug]/surveys/[surveyId]": [~91,[13]],
		"/[companySlug]/surveys/[surveyId]/access-requested": [~92],
		"/[companySlug]/surveys/[surveyId]/answer": [~93,[13,14]],
		"/[companySlug]/surveys/[surveyId]/answer/[...questionPath]": [~94,[13,14]],
		"/[companySlug]/surveys/[surveyId]/finish": [~95,[15]],
		"/[companySlug]/surveys/[surveyId]/finish/thank-you": [~96,[15]],
		"/[companySlug]/surveys/[surveyId]/no-access": [~97],
		"/[companySlug]/taxonomy": [~98,[16]],
		"/[companySlug]/taxonomy/overview": [~116,[16,22]],
		"/[companySlug]/taxonomy/overview/import-report": [~117,[16,22]],
		"/[companySlug]/taxonomy/[reportId]": [99,[17]],
		"/[companySlug]/taxonomy/[reportId]/company/minimumSafeguards": [~100,[17,18]],
		"/[companySlug]/taxonomy/[reportId]/company/pais": [~101,[17,18]],
		"/[companySlug]/taxonomy/[reportId]/evaluate": [~102,[17]],
		"/[companySlug]/taxonomy/[reportId]/evaluate/[entityId]": [~103,[17,19]],
		"/[companySlug]/taxonomy/[reportId]/evaluate/[entityId]/[activityId]": [~104,[17,19]],
		"/[companySlug]/taxonomy/[reportId]/financial": [~105,[17,20]],
		"/[companySlug]/taxonomy/[reportId]/financial/activity": [~107,[17,20]],
		"/[companySlug]/taxonomy/[reportId]/financial/layout": [108,[17,20]],
		"/[companySlug]/taxonomy/[reportId]/financial/[entityId]/[activityId]": [~106,[17,20]],
		"/[companySlug]/taxonomy/[reportId]/manage": [~109,[17]],
		"/[companySlug]/taxonomy/[reportId]/manage/[entityId]": [~110,[17]],
		"/[companySlug]/taxonomy/[reportId]/report": [111,[17]],
		"/[companySlug]/taxonomy/[reportId]/start": [~112,[17]],
		"/[companySlug]/taxonomy/[reportId]/summary": [113,[17,21]],
		"/[companySlug]/taxonomy/[reportId]/summary/downloads": [~114,[17,21]],
		"/[companySlug]/taxonomy/[reportId]/summary/kpi": [~115,[17,21]],
		"/[companySlug]/ungc": [118,[2,23],[3]],
		"/[companySlug]/ungc/category": [~119,[2,23],[3]],
		"/[companySlug]/ungc/themes": [~120,[2,23],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';